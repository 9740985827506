import React from "react";
import { Layout, SEO, ProBuy, ProFirst, TurnDevices, RedBuyBtn, ProcessNav } from '@/components/index'
import { Power, Details, DofBox, WiredBox, MoreThan } from '@/components/Beam/index'
import {
    beamProLogo, beamFirstBg_pc,
    beamFirstBg_mobile, beamBuyPro, beamBuyPro_mobile
} from '@/images/beam/index'
import { useIntl } from "react-intl"
import { useInView } from "react-intersection-observer"
import { buy_link } from '@/scripts/constant.js'
import getCountry from '@/utils/getCountry.js'


const Index = () => {
    const [dofRef, dofRefInView] = useInView({ threshold: 0, triggerOnce: true });
    const [dofRenderRef, dofRenderRefInView] = useInView({ threshold: 0 });
    const [headerRef, headerRefInView] = useInView({ threshold: 0 });
    const intl = useIntl()
    const countryCode = getCountry()
    const [wiredRef, wiredRefInView] = useInView({ threshold: 0, triggerOnce: true });
    const [turnRef, turnRefInView] = useInView({ threshold: 0, triggerOnce: true });
    const [powerRef, powerRefInView] = useInView({ threshold: 0, triggerOnce: true });
    const [morethanRef, morethanRefInView] = useInView({ threshold: 0, triggerOnce: true });
    const [detailRef, detailRefInView] = useInView({ threshold: 0, triggerOnce: true });

    const buyIntro = {
        img: beamBuyPro,
        mobileImg: beamBuyPro_mobile,
        name: "XREAL Beam",
        cate: "Beam",
        shop_link: buy_link[countryCode]['beam'].shop,
        amazon_link: buy_link[countryCode]['beam'].amazon,
        amazon_sale: '',
    }


    const proFirstMsg = {
        textBlack: true,
        desc: intl.formatMessage({ id: "beam.TurnEverythingSpatial" }),
        logo: beamProLogo,
        backgroundColor: "#d4d4d4",
        pcImg: beamFirstBg_pc,
        mobileImg: beamFirstBg_mobile,
        title: ""
    }
    const navList = [
        {
            id: 0,
            title: intl.formatMessage({ id: "progress_beam_0" }),
            classname: "beam-progress-0",
        },
        {
            id: 1,
            title: intl.formatMessage({ id: "progress_beam_1" }),
            classname: "beam-progress-1",
        }, {
            id: 2,
            title: intl.formatMessage({ id: "progress_beam_2" }),
            classname: "beam-progress-2",
        }, {
            id: 3,
            title: intl.formatMessage({ id: "progress_beam_3" }),
            classname: "beam-progress-3",
        }, {
            id: 4,
            title: intl.formatMessage({ id: "progress_beam_4" }),
            classname: "beam-progress-4",
        }, {
            id: 5,
            title: intl.formatMessage({ id: "progress_beam_5" }),
            classname: "beam-progress-5",
        }, {
            id: 6,
            title: intl.formatMessage({ id: "progress_beam_6" }),
            classname: "beam-progress-6",
        }

    ]
    return (
        <Layout showsubmenu={true} menuBlack={!headerRefInView && dofRenderRefInView ? false : true}>
            <SEO page="Beam" />
            <ProcessNav list={navList} />
            <RedBuyBtn cate='beam' />
            <div ref={headerRef} className='beam-progress-0'> <ProFirst msg={proFirstMsg} /></div>
            <div ref={dofRenderRef}>
                <div ref={dofRef} className='beam-progress-1'>
                    <DofBox />
                </div>
            </div>

            <div ref={wiredRef} className='beam-progress-2'>
                <WiredBox
                    ifShow={dofRefInView || morethanRefInView}
                />
            </div>
            <div ref={morethanRef} className='beam-progress-3'>
                {countryCode !== 'jp' && <MoreThan ifShow={wiredRefInView || turnRefInView} />}
            </div>
            <div ref={turnRef} className='beam-progress-4'><TurnDevices ifShow={morethanRefInView || powerRefInView} midDevice={"beam"} /></div>
            <div ref={powerRef} className='beam-progress-5'><Power ifShow={turnRefInView || detailRefInView} /></div>
            <div className='beam-progress-6'>
                <div ref={detailRef}> <Details /></div>

                <ProBuy pro_intro={buyIntro} />
            </div>

        </Layout>
    )
}

export default Index